import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursos-en-linea',
  templateUrl: './cursos-en-linea.component.html',
  styleUrls: ['./cursos-en-linea.component.scss']
})
export class CursosEnLineaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
