import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teachers-course',
  templateUrl: './teachers-course.component.html',
  styleUrls: ['./teachers-course.component.scss']
})
export class TeachersCourseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
