import { Component, OnInit } from '@angular/core';

// Servicios
import { SidenavService } from "./../../services/sidenav.service";

// Enviroment
import { environment } from "./../../../environments/environment";

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	menuArray = environment.menuArray


	constructor(private sidenav: SidenavService) { }

	ngOnInit() {
	}

	toggleActive: boolean = false;

	toggleRightSidenav() {
		this.toggleActive = !this.toggleActive;
		this.sidenav.toggle();
		console.log('Clicked');
	}
}
