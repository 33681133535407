import { Component } from '@angular/core';
import { Contacto } from "./contacto.model";
import { HttpClient } from '@angular/common/http';
import { environment } from "./../../../environments/environment";

@Component({
	selector: 'app-contacto',
	templateUrl: './contacto.component.html',
	styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent {

	title: string = 'Ubicación de ' + environment.titulo
	lat: number = environment.googleMaps.lat
	lng: number = environment.googleMaps.lng
	zoom: number = 16
	model
	mensajeMail:string
	direccionProcesadoraMail = environment.procesarMail.url
	destinatario = environment.correoElectronico



	constructor(private http: HttpClient) {
		this.model = new Contacto("", "", "", "", this.destinatario)
	}



	procesarFormulario() {
		this.http.post(this.direccionProcesadoraMail, this.model, { responseType: 'text' }).subscribe(mensaje=>{
			this.mensajeMail = mensaje

			this.model.nombre = ""
			this.model.telefonos = ""
			this.model.email = ""
			this.model.mensaje = ""
		})
	}
}
