import { Component, OnInit } from '@angular/core';

// Servicios
import { SidenavService } from "./../../services/sidenav.service";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}
