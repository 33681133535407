// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	titulo: "Montreal Institute",
	direccionWeb: "montrealinstitute.net",
	// correoElectronico: "alheranx@hotmail.com",
	correoElectronico: "montreal_institute@yahoo.com.mx",
	production: false,
	firebase: {
		apiKey: "AIzaSyCPYriLKBpHDIQLlHLpkCKeoHCfQMbGQlk",
		authDomain: "montreal-institute.firebaseapp.com",
		databaseURL: "https://montreal-institute.firebaseio.com",
		projectId: "montreal-institute",
		storageBucket: "montreal-institute.appspot.com",
		messagingSenderId: "909182003382",
		appId: "1:909182003382:web:366ef4a4198ead3f"
	},
	googleMaps: {
		key: "AIzaSyBQBYLetYi77Mr3rFgoEZ8SP8uWxv73oeE",
		lat: 19.39987417060369,
		lng: -99.01726216077805
	},
	procesarMail: {
		url: 'https://us-central1-montreal-institute.cloudfunctions.net/enviarMail'
	},
	menuArray: [
		{
			type: "link",
			route: "",
			icon: "icon icon-inicio",
			texto: "Inicio"
		},
		{
			type: "link",
			route: "montreal",
			icon: "icon icon-info",
			texto: "Acerca de Montreal"
		},
		{
			type: "link",
			route: "ingles",
			icon: "icon icon-mundo",
			texto: "Inglés"
		},
		{
			type: "link",
			route: "frances",
			icon: "icon icon-mundo",
			texto: "Francés"
		},
		{
			type: "dropdown",
			texto: "Cursos",
			submenu: [
				{
					type: "link",
					route: "teachers-course",
					icon: "icon icon-teachers",
					texto: "Teacher´s Course"
				},
				{
					type: "link",
					route: "clases-particulares-de-ingles",
					icon: "icon icon-user",
					texto: "Clases Partículares"
				},
				{
					type: "link",
					route: "traducciones-ingles-frances",
					icon: "icon icon-traducciones",
					texto: "Certificaciones"
				}, 
				{
					type: "link",
					route: "cursos-en-linea-de-ingles",
					icon: "icon icon-verano",
					texto: "Cursos en línea"
				},
				{
					type: "link",
					route: "cursos-para-empresas",
					icon: "icon icon-edificio",
					texto: "Cursos para Empresas"
				}
			]
		},
		{
			type: "link",
			route: "video-promocional",
			icon: "icon icon-videocamara",
			texto: "Experiencia Montreal"
		},
		{
			type: "link",
			route: "contacto",
			icon: "icon icon-contacto",
			texto: "Contacto"
		}
	]
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
