import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InicioComponent } from "./paginas/inicio/inicio.component";
import { MontrealComponent } from './paginas/montreal/montreal.component';
import { TeachersCourseComponent } from './paginas/teachers-course/teachers-course.component';
import { FrancesComponent } from './paginas/frances/frances.component';
import { InglesComponent } from './paginas/ingles/ingles.component';

import { CursosParaEmpresasComponent } from './paginas/cursos-para-empresas/cursos-para-empresas.component';
import { ClasesParticularesComponent } from './paginas/clases-particulares/clases-particulares.component';
import { CursosEnLineaComponent } from './paginas/cursos-en-linea/cursos-en-linea.component';
import { TraduccionesComponent } from './paginas/traducciones/traducciones.component';

import { VideoPromocionalComponent } from './paginas/video-promocional/video-promocional.component';
import { ContactoComponent } from "./paginas/contacto/contacto.component";
import { AdminComponent } from "./admin/admin/admin.component";


const routes: Routes = [
	{
		path: "",
		component: InicioComponent
	},
	{
		path: "montreal",
		component: MontrealComponent
	},
	{
		path: "teachers-course",
		component: TeachersCourseComponent
	},
	{
		path: "ingles",
		component: InglesComponent
	},
	{
		path: "frances",
		component: FrancesComponent
	},
	{
		path: "cursos-para-empresas",
		component: CursosParaEmpresasComponent
	},
	{
		path: "clases-particulares-de-ingles",
		component: ClasesParticularesComponent
	},
	{
		path: "cursos-en-linea-de-ingles",
		component: CursosEnLineaComponent
	},
	{
		path: "traducciones-ingles-frances",
		component: TraduccionesComponent
	},
	{
		path: "video-promocional",
		component: VideoPromocionalComponent
	},
	{
		path: "contacto",
		component: ContactoComponent
	},
	{
		path: "admin",
		component: AdminComponent
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
