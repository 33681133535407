export class Contacto {

	constructor(
	  public nombre: string,
	  public telefonos: string,
	  public email: string,
	  public mensaje: string,
	  public destinatario: string
	) {  }

     }
