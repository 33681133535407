import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './componentes/header/header.component';

import { environment } from "./../environments/environment";

// firebase
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";

// Angular material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule, MatDialogModule, MatInputModule, MatSidenavModule, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule, MatListModule, MatSelectModule, MatProgressBarModule } from "@angular/material";

// Servicios
import { SidenavService } from "./services/sidenav.service";

// Componentes
import { MontrealComponent } from './paginas/montreal/montreal.component';
import { TeachersCourseComponent } from './paginas/teachers-course/teachers-course.component';
import { FrancesComponent } from './paginas/frances/frances.component';
import { InglesComponent } from './paginas/ingles/ingles.component';
import { VideoPromocionalComponent } from './paginas/video-promocional/video-promocional.component';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { ContactoComponent } from './paginas/contacto/contacto.component';
import { CursosParaEmpresasComponent } from './paginas/cursos-para-empresas/cursos-para-empresas.component';
import { ClasesParticularesComponent } from './paginas/clases-particulares/clases-particulares.component';
import { CursosEnLineaComponent } from './paginas/cursos-en-linea/cursos-en-linea.component';
import { TraduccionesComponent } from './paginas/traducciones/traducciones.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { NavbarComponent } from './componentes/navbar/navbar.component';
import { SidenavComponent } from './componentes/sidenav/sidenav.component';


import { AgmCoreModule } from '@agm/core';
import { AdminComponent } from './admin/admin/admin.component';

// funciones
import { funcionesFormularios } from "./funciones/funciones-formularios";



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MontrealComponent,
    TeachersCourseComponent,
    FrancesComponent,
    InglesComponent,
    VideoPromocionalComponent,
    InicioComponent,
    ContactoComponent,
    CursosParaEmpresasComponent,
    ClasesParticularesComponent,
    CursosEnLineaComponent,
    TraduccionesComponent,
    FooterComponent,
    NavbarComponent,
    SidenavComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase), AngularFireDatabaseModule, AngularFirestoreModule, AngularFireStorageModule, AngularFireAuthModule,

    BrowserAnimationsModule,
    MatDialogModule, MatSidenavModule, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule, MatListModule, MatSelectModule, MatProgressBarModule, MatInputModule, MatTreeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.key
    }),
  ],
  providers: [SidenavService, funcionesFormularios],
  bootstrap: [AppComponent]
})
export class AppModule { }
