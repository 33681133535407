import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
// firebase
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
// funciones
import { funcionesFormularios } from "./../../funciones/funciones-formularios";


@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
	sliders
	formGroupSliders
	usuario: firebase.User
	progreso:number = 0
	imagenCargada:boolean = true
	imagenTemporal:string
	barraProgreso:boolean = false
	imagenDinamica
	@ViewChild('formDirective') formDirective

	constructor(
		private _formBuilder: FormBuilder,
		public archivos: AngularFireStorage,
		public datos: AngularFireDatabase,
		public autorizacion: AngularFireAuth,
		private funcionesFormularios: funcionesFormularios
	) {

		this.formGroupSliders = this._formBuilder.group({
			archivo: ["", Validators.required]
		})

	}

	ngOnInit() {
		// Traemos los sliders
		// let referencia = this.datos.database.ref('sliders')
		// let lista = this.datos.list(referencia)
		// lista.valueChanges().subscribe(data=>{
		// 	this.sliders = data
		// })


		let referencia = this.datos.database.ref('imagendinamica')
		let lista = this.datos.list(referencia)
		lista.valueChanges().subscribe(data=>{
			console.log(data[0])
			this.imagenDinamica = data[0]
		})
	}


	enviarFormularioRespaldo() {
		this.datos.database.ref('sliders').push(this.formGroupSliders.value).then(data => {
			this.formGroupSliders.value.archivo = ""
			this.imagenCargada = true
			this.imagenTemporal = ""
		})
	}

	enviarFormulario() {
		this.datos.database.ref('imagendinamica').set(this.formGroupSliders.value).then(data => {
			this.formGroupSliders.value.archivo = ""
			this.imagenCargada = true
			this.imagenTemporal = ""
		})
	}


	adjuntarArchivo(fileInput: any) {
		// Armando ruta y archivo
		const file = fileInput.target.files[0]
		const idArchivo = this.funcionesFormularios.generarFolio()
		const nombreCompuesto = idArchivo + "." + file.name.split('.')[1]
		const ref = this.archivos.ref(nombreCompuesto)
		// Creando tarea
		const task = ref.put(file);

		// Observar cambios en el porcentaje de subida
		task.percentageChanges().subscribe(snapshot => {
			this.progreso = Math.round(snapshot)
		})

		// Funcion que espera el final de la carga
		task.snapshotChanges().pipe(
			finalize(() => {
				ref.getDownloadURL().subscribe(snapshot => {
					this.formGroupSliders.value.archivo = snapshot
					this.imagenCargada = false
					this.imagenTemporal = snapshot
				})
			})
		).subscribe()
	}

}
