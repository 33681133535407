import { Component, OnInit } from '@angular/core';

// firebase
import { AngularFireDatabase } from "@angular/fire/database";

@Component({
	selector: 'app-inicio',
	templateUrl: './inicio.component.html',
	styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

	imagenDinamica

	constructor( private datos: AngularFireDatabase ) {

	}

	ngOnInit(){
		let referencia = this.datos.database.ref('imagendinamica')
		let lista = this.datos.list(referencia)
		lista.valueChanges().subscribe(data=>{
			console.log(data[0])
			this.imagenDinamica = data[0]
		})
	}
}
