import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from "@angular/material";

// Servicios
import { SidenavService } from "./services/sidenav.service";

// enviroment
import { environment } from "./../environments/environment";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'montreal-angular'
	menu = environment.menuArray
	toggleActive: boolean = false

	@ViewChild('sidenav') public sidenav: MatSidenav;

	constructor(private sidenavService: SidenavService) {
	}

	ngOnInit(): void {
		this.sidenavService.setSidenav(this.sidenav);
	}


	alsoClick() {
		this.toggleActive = !this.toggleActive
		this.sidenav.toggle()

		console.log("Diste click")
	}
}
