import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clases-particulares',
  templateUrl: './clases-particulares.component.html',
  styleUrls: ['./clases-particulares.component.scss']
})
export class ClasesParticularesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
